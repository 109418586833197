<template>
  <div>
    <h3 class="mb-4">Basic information</h3>
    <div class="mb-5">
      <inqli-avatar :image="previewAvatar" size="large" />
      <div class="form-item mt-2">
        <upload-file-button
          v-model="avatar"
          @change="setPreviewAvatar"
          title="Upload avatar"
          width="150"
        />
        <delete-file-button
          :resource-type="user.label"
          :resource-id="user.id"
          :fileUrl="user.avatar"
          v-if="user.id && user.avatar !== ''"
          width="150"
          @delete="onDeleteImage"
        />
      </div>
    </div>
    <div class="form-group">
      <v-text-field
        class="form-item"
        v-model="firstName"
        label="First name"
        :error-messages="inputError.firstName"
      ></v-text-field>
      <v-text-field
        class="form-item"
        v-model="lastName"
        label="Last name"
        :error-messages="inputError.lastName"
      ></v-text-field>
    </div>
    <div class="form-group mt-3">
      <v-select
        class="form-item"
        label="Pronouns"
        :items="pronounList"
        v-model="pronouns"
      />
    </div>
    <div class="form-group">
      <v-text-field
        class="form-item"
        v-model="org"
        label="Organization"
        :error-messages="inputError.org"
      ></v-text-field>
      <v-text-field
        class="form-item"
        v-model="orgDetail"
        label="Position"
        :error-messages="inputError.orgDetail"
      ></v-text-field>
    </div>
    <div class="form-group mt-3">
      <v-textarea
        class="form-item"
        label="About me"
        v-model.trim="about"
        dense
        rows="4"
        counter
      />
    </div>
    <h3 class="form-section-title">Location</h3>
    <location-input class="form-group" v-model="location" />
    <div>
      <h3 class="form-section-title">Skills - Interests - Values</h3>
      <multi-value-text-field
        class="form-item"
        label="Skills"
        v-model="skills"
        placeholder="type and select a skill"
        :select="true"
        :addable="true"
        :selectItemList="knownSkillList"
      />
      <multi-value-text-field
        class="form-item"
        label="Interests"
        v-model="interests"
        placeholder="type and select an interest"
        :select="true"
        :addable="true"
        :selectItemList="knownInterestList"
      />
      <multi-value-text-field
        class="form-item"
        label="Values"
        v-model="values"
        placeholder="type and select a value"
        :select="true"
        :selectItemList="knownValueList"
      />
    </div>
    <div class="form-buttons">
      <outline-button width="40%" @click="onCancel" v-if="!noCancel">
        Cancel
      </outline-button>
      <inqli-button width="40%" @click="onSubmit"> Submit</inqli-button>
    </div>
  </div>
</template>
<script>
import InqliAvatar from "../../app/components/avatars/Avatar.vue";
import MultiValueTextField from "../../app/components/inputs/MultiValueTextField.vue";
import UploadFileButton from "../../app/components/media/UploadFileButton.vue";
import DeleteFileButton from "../../app/components/media/DeleteFileButton.vue";
import InqliButton from "../../app/components/buttons/Button.vue";
import OutlineButton from "../../app/components/buttons/OutlineButton.vue";
import { required } from "vuelidate/lib/validators";
import sendRequest from "../../app/utils/send-request-helper";
import { isValidImageFormat } from "../../app/utils/validation-helper";
import LocationInput from "../../app/components/inputs/LocationInput.vue";

export default {
  name: "UserForm",
  components: {
    "inqli-avatar": InqliAvatar,
    "multi-value-text-field": MultiValueTextField,
    "inqli-button": InqliButton,
    "outline-button": OutlineButton,
    "location-input": LocationInput,
    "upload-file-button": UploadFileButton,
    "delete-file-button": DeleteFileButton,
  },
  data() {
    const defaultData = {
      firstName: "",
      lastName: "",
      pronouns: "they/them",
      type: "professional",
      org: "",
      orgDetail: "",
      about: "",
      avatar: null,
      previewAvatar: "",
      location: {
        city: "",
        state: "",
        country: "",
      },
      skills: this.userSkills || [],
      interests: this.userInterests || [],
      values: this.userValues || [],
      knownSkillList: [],
      knownInterestList: [],
      knownValueList: [],
      inputError: {
        firstName: "",
        lastName: "",
        org: "",
        orgDetail: "",
      },
    };
    if (this.user) {
      defaultData.firstName = this.user.first_name || "";
      defaultData.lastName = this.user.last_name || "";
      defaultData.pronouns = this.user.pronouns || "they/them";
      defaultData.type = this.user.type || "professional";
      defaultData.org = this.user.org || "";
      defaultData.orgDetail = this.user.org_detail || "";
      defaultData.about = this.user.about || "";
      defaultData.avatar = null;
      defaultData.previewAvatar = this.user.avatar || "";
      defaultData.location = {
        city: this.user.location ? this.user.location.city : "",
        state: this.user.location ? this.user.location.state : "",
        country: this.user.location ? this.user.location.country : "",
      };
      defaultData.skills = this.userSkills || [];
      defaultData.interests = this.userInterests || [];
      defaultData.values = this.userValues || [];
    }
    return defaultData;
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    org: {
      required,
    },
    orgDetail: {
      required,
    },
    avatar: {
      isValidAvatar(file) {
        if (file) {
          return isValidImageFormat(file);
        }
        return true;
      },
    },
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    userSkills: {
      type: Array,
      default: () => [],
    },
    userValues: {
      type: Array,
      default: () => [],
    },
    userInterests: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    noCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    suggestions() {
      return ["interests", "values"];
    },
    pronounList() {
      return ["he/him", "she/her", "they/them"];
    },
    userTypeList() {
      return ["professional", "post-secondary", "highschool"];
    },
  },
  async mounted() {
    try {
      const skillList = await sendRequest({
        url: `${process.env.VUE_APP_URL}/resources/skills`,
        method: "GET",
        isAuth: true,
      });
      this.knownSkillList = skillList.map((skill) => {
        return skill.value;
      });
      const interestList = await sendRequest({
        url: `${process.env.VUE_APP_URL}/resources/interests`,
        method: "GET",
        isAuth: true,
      });
      this.knownInterestList = interestList.map((interest) => {
        return interest.value;
      });
      const valueList = await sendRequest({
        url: `${process.env.VUE_APP_URL}/resources/values`,
        method: "GET",
        isAuth: true,
      });
      this.knownValueList = valueList.map((value) => {
        return value.value;
      });
    } catch (err) {
      this.$store.dispatch(
        "callAlert",
        "An error occurred while getting skills and interests"
      );
    }
  },
  watch: {
    firstName() {
      this.validateFirstName();
    },
    lastName() {
      this.validateLastName();
    },
    org() {
      this.validateOrg();
    },
    orgDetail() {
      this.validateOrgDetail();
    },
  },
  methods: {
    validateAvatar() {
      if (this.$v.avatar.$invalid) {
        this.inputError.avatar = "Please upload a valid image file";
        return false;
      } else {
        this.inputError.avatar = "";
        return true;
      }
    },
    setPreviewAvatar(file) {
      try {
        if (file && this.validateAvatar()) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.previewAvatar = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.avatar = null;
          this.previewAvatar = "";
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          error.message || "Can not upload image."
        );
      }
    },
    onCancel() {
      this.$emit("cancel");
    },
    onDeleteImage() {
      this.avatar = "";
      this.previewAvatar = "";
      this.$emit("submit", {
        user: {
          avatar: "",
        },
      });
    },
    onSubmit() {
      const user = {
        first_name: this.firstName,
        last_name: this.lastName,
        pronouns: this.pronouns,
        type: this.type,
        org: this.org,
        about: this.about,
        org_detail: this.orgDetail,
        avatar: this.avatar,
        location: this.location,
      };
      if (this.validate()) {
        this.$emit("submit", {
          user,
          skills: this.skills,
          interests: this.interests,
          values: this.values,
          isDone: true,
        });
      }
    },
    validate() {
      if (this.$v.$invalid) {
        this.validateFirstName();
        this.validateLastName();
        this.validateOrg();
        this.validateOrgDetail();
        this.validateAvatar();
        return false;
      }
      return true;
    },
    validateFirstName() {
      if (this.$v.firstName.$invalid) {
        this.inputError.firstName = "First name is required.";
      } else {
        this.inputError.firstName = "";
      }
    },
    validateLastName() {
      if (this.$v.lastName.$invalid) {
        this.inputError.lastName = "Last name is required.";
      } else {
        this.inputError.lastName = "";
      }
    },
    validateOrg() {
      if (this.$v.org.$invalid) {
        this.inputError.org = "Organization is required.";
      } else {
        this.inputError.org = "";
      }
    },
    validateOrgDetail() {
      if (this.$v.orgDetail.$invalid) {
        this.inputError.orgDetail = "Position is required.";
      } else {
        this.inputError.orgDetail = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-section-title {
  margin-top: 10px;
}
.form-group {
  display: flex;
}
.form-item {
  margin: 0 20px;
}
.form-buttons {
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-top: 30px;
  width: 100%;
}
</style>
