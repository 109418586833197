var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-4" }, [_vm._v("Basic information")]),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("inqli-avatar", {
            attrs: { image: _vm.previewAvatar, size: "large" }
          }),
          _c(
            "div",
            { staticClass: "form-item mt-2" },
            [
              _c("upload-file-button", {
                attrs: { title: "Upload avatar", width: "150" },
                on: { change: _vm.setPreviewAvatar },
                model: {
                  value: _vm.avatar,
                  callback: function($$v) {
                    _vm.avatar = $$v
                  },
                  expression: "avatar"
                }
              }),
              _vm.user.id && _vm.user.avatar !== ""
                ? _c("delete-file-button", {
                    attrs: {
                      "resource-type": _vm.user.label,
                      "resource-id": _vm.user.id,
                      fileUrl: _vm.user.avatar,
                      width: "150"
                    },
                    on: { delete: _vm.onDeleteImage }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("v-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "First name",
              "error-messages": _vm.inputError.firstName
            },
            model: {
              value: _vm.firstName,
              callback: function($$v) {
                _vm.firstName = $$v
              },
              expression: "firstName"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Last name",
              "error-messages": _vm.inputError.lastName
            },
            model: {
              value: _vm.lastName,
              callback: function($$v) {
                _vm.lastName = $$v
              },
              expression: "lastName"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group mt-3" },
        [
          _c("v-select", {
            staticClass: "form-item",
            attrs: { label: "Pronouns", items: _vm.pronounList },
            model: {
              value: _vm.pronouns,
              callback: function($$v) {
                _vm.pronouns = $$v
              },
              expression: "pronouns"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("v-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Organization",
              "error-messages": _vm.inputError.org
            },
            model: {
              value: _vm.org,
              callback: function($$v) {
                _vm.org = $$v
              },
              expression: "org"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Position",
              "error-messages": _vm.inputError.orgDetail
            },
            model: {
              value: _vm.orgDetail,
              callback: function($$v) {
                _vm.orgDetail = $$v
              },
              expression: "orgDetail"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-group mt-3" },
        [
          _c("v-textarea", {
            staticClass: "form-item",
            attrs: { label: "About me", dense: "", rows: "4", counter: "" },
            model: {
              value: _vm.about,
              callback: function($$v) {
                _vm.about = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "about"
            }
          })
        ],
        1
      ),
      _c("h3", { staticClass: "form-section-title" }, [_vm._v("Location")]),
      _c("location-input", {
        staticClass: "form-group",
        model: {
          value: _vm.location,
          callback: function($$v) {
            _vm.location = $$v
          },
          expression: "location"
        }
      }),
      _c(
        "div",
        [
          _c("h3", { staticClass: "form-section-title" }, [
            _vm._v("Skills - Interests - Values")
          ]),
          _c("multi-value-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Skills",
              placeholder: "type and select a skill",
              select: true,
              addable: true,
              selectItemList: _vm.knownSkillList
            },
            model: {
              value: _vm.skills,
              callback: function($$v) {
                _vm.skills = $$v
              },
              expression: "skills"
            }
          }),
          _c("multi-value-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Interests",
              placeholder: "type and select an interest",
              select: true,
              addable: true,
              selectItemList: _vm.knownInterestList
            },
            model: {
              value: _vm.interests,
              callback: function($$v) {
                _vm.interests = $$v
              },
              expression: "interests"
            }
          }),
          _c("multi-value-text-field", {
            staticClass: "form-item",
            attrs: {
              label: "Values",
              placeholder: "type and select a value",
              select: true,
              selectItemList: _vm.knownValueList
            },
            model: {
              value: _vm.values,
              callback: function($$v) {
                _vm.values = $$v
              },
              expression: "values"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-buttons" },
        [
          !_vm.noCancel
            ? _c(
                "outline-button",
                { attrs: { width: "40%" }, on: { click: _vm.onCancel } },
                [_vm._v(" Cancel ")]
              )
            : _vm._e(),
          _c(
            "inqli-button",
            { attrs: { width: "40%" }, on: { click: _vm.onSubmit } },
            [_vm._v(" Submit")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }